import { useNavigate, useLocation } from 'react-router-dom'
import Image from 'next/image'
import {
  ROUTES_TO_BE_HIDDEN,
  RouteId,
  RouteType,
  devRoutes,
  routes as originalRoutes,
} from '../../constants/routes'
import { useWeb3 } from '../../context/Web3Context'
import { useEffect, useState } from 'react'
import { assetListMap } from '../../constants/contract/asset'
import { getAssetsInfoByDeployerOrOperator } from '../../utils/voting'
import {
  useBribeConfigData,
  useBoostedPoolRewarderData,
  useVotingData,
} from '../../store/MulticallData/hooks'
import viewOnIcon from '../../public/assets/icons/blank-page.svg'

const RouterTabs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { chainId } = useWeb3()

  const [showBribeLink, setShowBribeLink] = useState(false)
  const votingData = useVotingData()
  const bribeConfigData = useBribeConfigData()
  const poolRewarderData = useBoostedPoolRewarderData()
  const localIsDev = localStorage.getItem('isDev')
  const isDev = localIsDev ?? localIsDev === 'true'
  const routes = isDev ? devRoutes : originalRoutes

  useEffect(() => {
    const assetList = assetListMap[chainId]
    const isbribeDeployerOfEachAsset = votingData.withAccount?.isbribeDeployerOfEachAsset
    const isUserHasBribeRoleOfEachAsset = bribeConfigData.withAccount?.userHasBribeRoleOfEachAsset
    let hasRoles =
      getAssetsInfoByDeployerOrOperator(
        isbribeDeployerOfEachAsset,
        isUserHasBribeRoleOfEachAsset,
        assetList,
        'bribeRewarder'
      ).length > 0

    if (!hasRoles) {
      hasRoles =
        getAssetsInfoByDeployerOrOperator(
          poolRewarderData.withAccount?.hasDeployerRoleOfEachAsset,
          poolRewarderData.withAccount?.hasOperatorRoleOfEachAsset,
          assetList,
          'boostedPoolRewarder'
        ).length > 0
    }
    setShowBribeLink(hasRoles)
  }, [
    bribeConfigData.withAccount?.userHasBribeRoleOfEachAsset,
    chainId,
    poolRewarderData.withAccount?.hasDeployerRoleOfEachAsset,
    poolRewarderData.withAccount?.hasOperatorRoleOfEachAsset,
    votingData.withAccount?.isbribeDeployerOfEachAsset,
  ])

  const [desktopRoutes, setDesktopRoutes] = useState<RouteType[]>([])

  useEffect(() => {
    const dekstopRoutes_ = Object.entries(routes)
      .filter(([routeId, route]) => {
        if (route.name.toLowerCase() === 'bribe') {
          return showBribeLink
        }

        return route.visibleOnNavbar && !ROUTES_TO_BE_HIDDEN[routeId as RouteId]?.includes(chainId)
      })
      .map(([, routes]) => routes)
    setDesktopRoutes(dekstopRoutes_)
  }, [chainId, routes, showBribeLink])

  return (
    <div className="ml-3 hidden md:block lg:ml-8">
      <div className="flex p-1 font-Work text-xs font-semibold text-wombatBrown1 lg:text-base">
        {desktopRoutes.map((route) => {
          return (
            <button
              key={route.name}
              id={`routertabs-${route.name.toLowerCase()}`}
              className={`${
                route.path === pathname && 'font-extrabold text-wombatBrown'
              } button-hover-opacity px-0.5 uppercase lg:px-5`}
              onClick={() => {
                if (route.isExternalLink !== true) {
                  navigate(route.path)
                }
              }}
            >
              {route.isExternalLink === true ? (
                <a href={route.path} target="_blank" rel="noreferrer">
                  <span>{route.name}</span>
                  &nbsp;
                  <Image
                    src={viewOnIcon}
                    height={14}
                    width={14}
                    style={{ marginLeft: '2px', marginTop: '1px' }}
                  />
                </a>
              ) : (
                <span>{route.name}</span>
              )}
            </button>
          )
        })}
      </div>
    </div>
  )
}
export default RouterTabs
